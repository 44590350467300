import BaoKimPaymentType from 'Enum/BaoKimPaymentType'
const DOMAIN_BASE_PATH = window.location.protocol + '//' + window.location.host + process.env.VUE_APP_BASE_PATH

const ObjectSettingKey = {
  PAYMENT_MOMO_ENABLE_PAYMENT: 'payment_momo_method_enabled',
  PAYMENT_MOMO_PARTNER_CODE: 'payment_momo_partner_code',
  PAYMENT_MOMO_ACCESS_CODE: 'payment_momo_access_code',
  PAYMENT_MOMO_SECRET_KEY: 'payment_momo_secret_key'
}

const BaoKimPaymentMethodList = [
  {
    text: 'baoKimConfig.paymentMethod.BAO_KIM_WALLET',
    value: BaoKimPaymentType.BAO_KIM_WALLET
  },
  {
    text: 'baoKimConfig.paymentMethod.ATM_CARD_ONLINE',
    value: BaoKimPaymentType.ATM_CARD_ONLINE
  },
  {
    text: 'baoKimConfig.paymentMethod.VISA_MASTER_CARD',
    value: BaoKimPaymentType.VISA_MASTER_CARD
  },
  {
    text: 'baoKimConfig.paymentMethod.TRANSFER',
    value: BaoKimPaymentType.TRANSFER
  },
  {
    text: 'baoKimConfig.paymentMethod.QR_CODE',
    value: BaoKimPaymentType.QR_CODE
  },
  // {
  //   text: 'baoKimConfig.paymentMethod.INSTALLMENT',
  //   value: BaoKimPaymentType.INSTALLMENT
  // },
  // {
  //   text: 'baoKimConfig.paymentMethod.BNPL',
  //   value: BaoKimPaymentType.BNPL
  // },
  // {
  //   text: 'baoKimConfig.paymentMethod.MOMO',
  //   value: BaoKimPaymentType.MOMO
  // },
  // {
  //   text: 'baoKimConfig.paymentMethod.VIETTEL',
  //   value: BaoKimPaymentType.VIETTEL
  // },
  // {
  //   text: 'baoKimConfig.paymentMethod.ZALO',
  //   value: BaoKimPaymentType.ZALO
  // },
  // {
  //   text: 'baoKimConfig.paymentMethod.VIRTUAL_ACCOUNT',
  //   value: BaoKimPaymentType.VIRTUAL_ACCOUNT
  // }
]

export default {
  DOMAIN_BASE_PATH,
  ObjectSettingKey,
  BaoKimPaymentMethodList
}