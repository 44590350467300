const BaoKimPaymentType = {
  BAO_KIM_WALLET: 0,
  ATM_CARD_ONLINE: 1,
  VISA_MASTER_CARD: 2,
  TRANSFER: 3,
  QR_CODE: 13,
  INSTALLMENT: 14,
  BNPL: 15,
  MOMO: 17,
  VIETTEL: 18,
  ZALO: 19
  // VIRTUAL_ACCOUNT: 16
}
export default BaoKimPaymentType
