<template>
  <v-main>
    <introNavigation />
    <v-container fluid fill-height>
      <v-row align-center justify-center>
        <v-flex xs12 sm12 md12>
          <v-col cols="12" md="6" class="mx-auto">
            <p class="display-1">{{ $t('confirmActiveKey.welcomeTo') }}</p>
            <p class="text-welcome">
              {{ $t('confirmActiveKey.onlineShop') }}
              <span class="font-weight-bold">CONASI365</span>
            </p>
            <p>
              {{ $t('confirmEmailDomain.toContinueEnterYourEmailDomain') }}
            </p>
            <v-form
              ref="entryForm"
              @submit.prevent="onVerifyActiveKey"
              v-model="valid"
            >
              <v-text-field
                :rules="emailRules"
                v-model="emailDomain"
                :label="$t('confirmEmailDomain.emailDomain')"
                :hint="$t('confirmEmailDomain.emailDomainToLink')"
                @keypress.enter="onVerifyActiveKey"
                outlined
              ></v-text-field>
              <div class="text-left">
                <v-btn color="primary" type="submit" :loading="isLoading" :disabled="countdownInterval > 0"
                  >{{ $t('confirmModal.btn.confirm') }} <span v-if="countdownInterval > 0">({{ countdownInterval }})</span></v-btn
                >
              </div>
            </v-form>
          </v-col>
        </v-flex>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";
import { isCookieEnabled, getCookie, setCookie } from "tiny-cookie";
import IntroNavigation from "Components/IntroNavigation";
import stringUtils from "Utils/stringUtils";
import functionUtils from "Utils/functionUtils";
export default {
  components: {
    IntroNavigation,
  },
  data: () => ({
    emailDomain: null,
    valid: false,
    isVerifySuccess: false,
    emailRules: [],
    checkingActivationCounting: 0,
    countdownInterval: 0,
    isLoading: false
  }),
  watch: {
    emailDomain() {
      this.emailRules = [];
    },
    checkingActivationCounting: {
      handler() {
        this.onCheckUserIsActivated(true)
      }
    },
    countdownInterval: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.countdownInterval--;
          }, 1000);
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  created () {
    this.onCheckUserIsActivated(false)
  },
  methods: {
    emailNotExists() {
      return this.$t('confirmEmailDomain.emailDomainNotExist');
    },
    /**
     * Verify active key
     */
    onVerifyActiveKey: function() {
      this.emailRules = [
        (v) => !!v || this.$t('login.thisFieldIsRequired'),
        (v) => /.+@.+\..+/.test(v) || this.$t('login.emailMustBeValid'),
      ];
      if (this.$refs.entryForm.validate()) {
        let filter = {
          email: this.emailDomain,
          domain: stringUtils.DOMAIN_BASE_PATH,
        };
        this.isLoading = true;
        this.VERIFY_EMAIL_DOMAIN(filter)
          .then(
            function(res) {
              this.isLoading = false;
              let data = res.data;
              if (data.isActive) {
                setCookie('isActiveLink', data.isActive)
                this.$router.push({
                  name: "Home",
                });
              } else {
                this.$toast.open({
                  message: this.$t('confirmEmailDomain.successfulWaitForLinkConfirm'),
                  type: "success",
                  ...this.$toastConfig,
                });
                // Start checking activation
                if (this.checkingActivationCounting == 0) {
                  this.checkingActivationCounting++
                }
                // Wait for 60 seconds for resend email
                this.countdownInterval = 60
              }
            }.bind(this)
          )
          .catch(
            function() {
              this.isLoading = false;
              this.emailRules.push(this.emailNotExists);
              this.$refs.entryForm.validate();
            }.bind(this)
          );
      }
    },
    onCheckUserIsActivated: function(recheckIfFail) {
      const userId = isCookieEnabled() ? getCookie("userId") : null;
      if (!functionUtils.isNull(userId)) {
        this.CHECK_USER_IS_ACTIVATED({ id: userId }).then(
          function(res) {
            let data = res.data;
            if (data.isActive) {
              setCookie('isActiveLink', data.isActive)
              this.$router.push({
                name: "Home",
              });
            } else {
              if (recheckIfFail) {
                // Continue checking in next second
                setTimeout(() => {
                  this.checkingActivationCounting++
                }, 2000);
              }
            }
          }.bind(this)
        )
      }
    },
    ...mapActions(['VERIFY_EMAIL_DOMAIN', 'CHECK_USER_IS_ACTIVATED']),
  },
};
</script>

<style lang="scss" scoped>
.text-welcome {
  color: #FE6D6D !important;
  font-size: 24px;
  font-weight: 600;
}
</style>
