<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary light>
      <div class="py-2">
        <v-btn text @click="goTo('Home')" style="height: 100px;">
          <img
            src="@/assets/conasi_logo.png"
            alt="Logo"
            width="200px"
            height="50px"
          />
        </v-btn>
      </div>
      <v-divider />
      <v-list dense>
        <v-list-item
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          @click="$vuetify.goTo(link)"
        >
          <v-list-item-icon class="justify-center">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="text-left">
            <v-list-item-title class="subtitile-1" v-if="text == 'GO_CONASI_PAGE'" @click="goConasi365">
              <span>{{ $t("common.goToPage") }}
                <span class="font-weight-bold">conasi365.vn</span></span>
            </v-list-item-title>
            <v-list-item-title class="subtitile-1" v-else-if="text == 'SUPPORT'">{{
              $t("menu.support") + ': 1900636870'
            }}</v-list-item-title>
            <v-list-item-title class="subtitile-1" v-else @click="onChangeLocale">{{
              $i18n.locale === "en" ? "VI" : "EN"
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app light height="80px">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        v-if="isXs"
      />
      <div v-else class="d-flex justify-lg-space-between align-center w-100">
        <v-toolbar-title class="cursor-pointer" style="flex: 0.33" @click="goConasi365">
          <span
            ><v-icon>mdi-chevron-left</v-icon>{{ $t("common.goToPage") }}
            <span class="font-weight-bold">conasi365.vn</span></span
          >
        </v-toolbar-title>
        <div style="flex: 0.33">
          <v-btn text @click="goConasi365()" style="height: auto;">
            <img
              src="@/assets/conasi_logo.png"
              alt="Logo"
              width="240px"
              height="55px"
            />
          </v-btn>
        </div>
        <div style="flex: 0.33">
          <span class="px-2 cursor-pointer" @click="onChangeLocale">{{
            $i18n.locale === "en" ? "VI" : "EN"
          }}</span>
          <span class="px-2">{{ $t("menu.support") }}: 1900636870</span>
          <v-icon class="px-2">mdi-phone-outline</v-icon>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    products: 0,
    drawer: null,
    isXs: false,
    items: [
      ["mdi-home-outline", "GO_CONASI_PAGE", "#hero"],
      ["mdi-phone-outline", "SUPPORT", "#features"],
      ["mdi-web", "LANGUAGE", "#features"],
      // ["mdi-newspaper", "menu.news", "#distribution"],
    ],
    logoUrl: null,
    color: null,
    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  created () {
    // Temporary: force display Vietamese if not EN
    let locale = this.$i18n.locale === "en" ? "en" : "vi";
    this.CHANGE_LOCALE(locale);
  },
  methods: {
    goConasi365: function () {
      window.open("https://conasi365.vn/");
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    /**
     * Change locale
     */
    onChangeLocale() {
      let locale = this.$i18n.locale === "en" ? "vi" : "en";
      this.CHANGE_LOCALE(locale);
    },
    ...mapActions(["CHANGE_LOCALE"]),
  },
};
</script>
<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
</style>
